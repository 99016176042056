<template>
  <v-container>
    <v-row>
      <v-col cols="12" :sm="12" class="pr-0">
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" />
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="gruposDevengamiento"
      class="elevation-1"
      :search="search"
      show-expand
      item-key="dgId"
      :expanded.sync="expanded"
      :loading="isLoading"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-row>
            <v-col cols="8">
              <v-text-field
                v-model="search"
                :append-icon="searchIcon"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="4" align="end" v-if="canCreate">
              <v-btn color="primary" @click="openModal()" class="to-right">
                Nuevo
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left v-if="canEdit">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              size="20"
              v-on="on"
              @click="openModal(item.dgId)"
            >
              {{ editIcon }}
            </v-icon>
          </template>
          <span>Editar grupo</span>
        </v-tooltip>
        <v-tooltip left v-if="canDelete">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              size="20"
              v-on="on"
              @click="deleteGrupo(item.dgId)"
            >
              {{ deleteIcon }}
            </v-icon>
          </template>
          <span>Eliminar grupo</span>
        </v-tooltip>
      </template>
      <!-- Expansion de usuario -->
      <template v-slot:[`item.data-table-expand`]="{ item, isExpanded }">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              @click="handleExpansion(item, isExpanded)"
              v-bind="attrs"
              size="20"
              v-on="on"
              >{{ isExpanded ? "mdi-account-check" : "mdi-account" }}</v-icon
            >
          </template>
          <span>Usuarios</span>
        </v-tooltip>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <strong>Fecha alta:</strong>
          {{ item.fecAlta }}
          <strong style="padding-left: 80px">Usuario alta:</strong>
          {{ item.usuAlta }}
          <strong style="padding-left: 80px">Fecha modificación:</strong>
          {{ item.fecModi }}
          <strong style="padding-left: 80px">Usuario modificación:</strong>
          {{ item.usuModi }}
        </td>
      </template>
    </v-data-table>
    <v-dialog
      v-if="openModalEdit"
      v-model="openModalEdit"
      max-width="40%"
      @keydown.esc="closeAndReload"
      persistent
    >
      <EditGrupoDevengamiento
        :editGrupoDevengamientoId="editGrupoDevengamientoId"
        @closeAndReload="closeAndReload"
      ></EditGrupoDevengamiento>
    </v-dialog>
    <DeleteDialog
      :titleProp="titleDelete"
      :maxWidth="'40%'"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import GoBackBtn from "@/components/shared/GoBackBtn";
import DeleteDialog from "@/components/shared/DeleteDialog";
import EditGrupoDevengamiento from "@/views/modules/cuotas/configuracion/EditGrupoDevengamiento.vue";
import enums from "@/utils/enums/index.js";
import { mapActions } from "vuex";
export default {
  components: { PageHeader, DeleteDialog, EditGrupoDevengamiento, GoBackBtn },
  data() {
    return {
      routeToGo: "DevengamientosCuotas",
      title: enums.titles.GRUPOS_DE_DEVENGAMIENTO,
      titleDelete: enums.titles.DELETE_GRUPO_DEVENGAMIENTO,
      searchIcon: enums.icons.SEARCH,
      editIcon: enums.icons.EDIT,
      deleteIcon: enums.icons.DELETE,
      search: "",
      expanded: [],
      gruposDevengamiento: [],
      editGrupoDevengamientoId: {},
      allowedActions: null,
      showDeleteModal: false,
      openModalEdit: false,
      canEdit: false,
      canDelete: false,
      canCreate: false,
      isLoading: false,
      headers: [
        {
          text: "Nombre",
          align: "start",
          value: "dgNombre",
          sortable: false,
        },
        {
          text: "Vigencia desde",
          align: "center",
          value: "dgVigenciaDesde",
          sortable: false,
        },
        {
          text: "Vigencia hasta",
          align: "center",
          value: "dgVigenciaHasta",
          sortable: false,
        },
        { text: "Acciones", value: "actions", sortable: false, align: "end" },
        {
          text: "",
          value: "data-table-expand",
          sortable: false,
          align: "end",
        },
      ],
    };
  },
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false,
    });
    this.setGruposDevengamiento();
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      deleteGrupoDevengamiento: "configuracion/deleteGrupoDevengamiento",
      getGruposDevengamiento: "configuracion/getGruposDevengamiento",
      setAlert: "user/setAlert",
    }),
    setPermisos() {
      this.allowedActions?.forEach((x) => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.NUEVO_GRUPO_DEVENGAMIENTO:
            this.canCreate = true;
            break;
          case enums.modules.adminSistema.permissions
            .EDITAR_GRUPO_DEVENGAMIENTO:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions
            .ELIMINAR_GRUPO_DEVENGAMIENTO:
            this.canDelete = true;
            break;
          default:
            break;
        }
      });
    },
    async setGruposDevengamiento() {
      this.isLoading = true;
      const data = await this.getGruposDevengamiento();
      this.gruposDevengamiento = data;
      this.isLoading = false;
    },
    openModal(item) {
      this.openModalEdit = true;
      this.editGrupoDevengamientoId = item;
    },
    deleteGrupo(item) {
      this.showDeleteModal = true;
      this.itemToDelete = item;
    },
    async confirmDelete() {
      const response = await this.deleteGrupoDevengamiento(this.itemToDelete);
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.setGruposDevengamiento();
      }
    },
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },
    closeAndReload() {
      this.openModalEdit = false;
      this.setGruposDevengamiento();
    },
  },
};
</script>