<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveEdit()"
        >
          <!-- NOMBRE -->
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-textarea
                v-model.trim="dgNombre"
                auto-grow
                :rules="
                  rules.required.concat([
                    rules.maxLength(dgNombre, 100),
                    rules.requiredTrim(dgNombre)
                  ])
                "
                dense
                outlined
                label="Nombre"
                rows="1"
              ></v-textarea>
            </v-col>
          </v-row>
          <!-- Fecha vigencia desde -->
          <v-row>
            <v-col cols="6" class="py-0">
              <v-menu
                v-model="menuFechaDesde"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fechaSelectedDesde"
                    label="Vigencia desde"
                    :append-icon="calendarIcon"
                    v-bind="attrs"
                    autocomplete="not"
                    clearable
                    hint="Formato DD/MM/AAAA"
                    @blur="fechaDesde = parseDateToIso(fechaSelectedDesde)"
                    :rules="
                      rules.required.concat(
                        rules.validDate,
                        new Date(parseDateToIso(fechaSelectedDesde)) <=
                          new Date(parseDateToIso(fechaSelectedHasta)) ||
                          'Formato incorrecto'
                      )
                    "
                    outlined
                    dense
                    v-mask="'##/##/####'"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaDesde"
                  no-title
                  @change="fechaSelectedDesde = formatDate(fechaDesde)"
                  @input="menuFechaDesde = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <!-- Fecha vigencia hasta -->
            <v-col cols="6" class="py-0">
              <v-menu
                v-model="menuFechaHasta"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fechaSelectedHasta"
                    label="Vigencia hasta"
                    :append-icon="calendarIcon"
                    v-bind="attrs"
                    autocomplete="not"
                    clearable
                    hint="Formato DD/MM/AAAA"
                    @blur="fechaHasta = parseDateToIso(fechaSelectedHasta)"
                    :rules="
                      rules.required.concat(
                        rules.validDate,
                        new Date(parseDateToIso(fechaSelectedDesde)) <=
                          new Date(parseDateToIso(fechaSelectedHasta)) ||
                          'Formato incorrecto'
                      )
                    "
                    outlined
                    dense
                    v-mask="'##/##/####'"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaHasta"
                  no-title
                  @change="fechaSelectedHasta = formatDate(fechaHasta)"
                  @input="menuFechaHasta = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <!-- COBRADORES -->
          <v-row>
            <v-col cols="12" class="py-0">
              <v-autocomplete
                class="mb-0 mt-0 pt-0"
                outlined
                clearable
                dense
                multiple
                v-model="cobradoresSelect"
                label="Cobradores"
                item-text="value"
                item-value="id"
                autocomplete="on"
                :items="cobradoresItems"
                return-object
              >
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="cobradoresToggle()">
                    <v-list-item-action>
                      <v-icon
                        :color="cobradoresSelect.length > 0 ? 'primary' : ''"
                      >
                        {{ multiselectIconCobradores }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Todos </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.value }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text caption">
                    (+{{ cobradoresSelect.length - 1 }} otros)
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <!-- CONVENIOS -->
          <v-row>
            <v-col cols="12" class="py-0">
              <v-autocomplete
                class="mb-0 mt-0 pt-0"
                outlined
                clearable
                dense
                multiple
                v-model="conveniosSelect"
                label="Convenios"
                item-text="value"
                item-value="id"
                autocomplete="on"
                :items="conveniosItems"
                return-object
              >
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="conveniosToggle()">
                    <v-list-item-action>
                      <v-icon
                        :color="conveniosSelect.length > 0 ? 'primary' : ''"
                      >
                        {{ multiselectIconConvenios }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Todos </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.value }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text caption">
                    (+{{ conveniosSelect.length - 1 }} otros)
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <!-- FORMAS DE PAGO -->
          <v-row>
            <v-col cols="12" class="py-0">
              <v-autocomplete
                class="mb-0 mt-0 pt-0"
                outlined
                clearable
                dense
                multiple
                v-model="formasPagoSelect"
                label="Formas de pago"
                item-text="value"
                item-value="id"
                autocomplete="on"
                :items="formasPagoItems"
                return-object
              >
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="formasPagoToggle()">
                    <v-list-item-action>
                      <v-icon
                        :color="formasPagoSelect.length > 0 ? 'primary' : ''"
                      >
                        {{ multiselectIconFormasPago }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Todos </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.value }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text caption">
                    (+{{ formasPagoSelect.length - 1 }} otros)
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <!-- Acciones de cancelar y guardar -->
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined @click="closeModal"> Cancelar </v-btn>
            <v-btn
              type="submit"
              :disabled="!isFormValid"
              form="form"
              color="primary"
            >
              Guardar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-container>
  </v-card>
</template>

<script>
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";
import { mapActions } from "vuex";
export default {
  directives: { mask },
  props: {
    editGrupoDevengamientoId: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      rules: rules,
      formEditTitle: enums.titles.EDIT_GRUPO_DEVENGAMIENTO,
      newTitle: enums.titles.NUEVO_GRUPO_DEVENGAMIENTO,
      calendarIcon: enums.icons.CALENDAR,
      mSCloseBoxIcon: enums.icons.CLOSE_BOX,
      checkBoxBlankOutlineIcon: enums.icons.CHECKBOX_BLANK_OUTLINE,
      dgNombre: "",
      fechaSelectedDesde: null,
      fechaSelectedHasta: null,
      cobradoresSelect: [],
      conveniosSelect: [],
      formasPagoSelect: [],
      cobradoresItems: [],
      formasPagoItems: [],
      conveniosItems: [],
      grupoDevengamiento: {},
      isFormValid: false,
      menuFechaDesde: false,
      menuFechaHasta: false,
      fechaDesde: null,
      fechaHasta: null
    };
  },
  async mounted() {
    await this.setSelects();
    if (this.editGrupoDevengamientoId != null) this.setGrupoDevengamiento();
    else this.newGrupoDevengamiento();
  },
  computed: {
    multiselectIconCobradores() {
      if (this.selectAllCobradores) return this.mSCloseBoxIcon;
      return this.checkBoxBlankOutlineIcon;
    },
    multiselectIconConvenios() {
      if (this.selectAllConvenios) return this.mSCloseBoxIcon;
      return this.checkBoxBlankOutlineIcon;
    },
    multiselectIconFormasPago() {
      if (this.selectAllFormasPago) return this.mSCloseBoxIcon;
      return this.checkBoxBlankOutlineIcon;
    },
    selectAllCobradores() {
      return (
        this.cobradoresSelect &&
        this.cobradoresSelect.length === this.cobradoresItems.length
      );
    },
    selectAllFormasPago() {
      return (
        this.formasPagoSelect &&
        this.formasPagoSelect.length === this.formasPagoItems.length
      );
    },
    selectAllConvenios() {
      return (
        this.conveniosSelect &&
        this.conveniosSelect.length === this.conveniosItems.length
      );
    }
  },
  methods: {
    ...mapActions({
      postGrupoDevengamiento: "configuracion/postGrupoDevengamiento",
      getGrupoDevengamientoPorId: "configuracion/getGrupoDevengamientoPorId",
      getCobradores: "afiliaciones/getCobradores",
      getFormasPago: "devengamientos/getFormasDePago",
      getConvenios: "aportes/fetchConvenios",
      setAlert: "user/setAlert"
    }),
    async setGrupoDevengamiento() {
      const response = await this.getGrupoDevengamientoPorId(
        this.editGrupoDevengamientoId
      );
      this.grupoDevengamiento = response;
      this.dgNombre = this.grupoDevengamiento.dgNombre;
      this.fechaSelectedDesde = this.grupoDevengamiento.dgVigenciaDesde;
      this.fechaDesde = this.parseDateToIso(
        this.grupoDevengamiento.dgVigenciaDesde
      );
      this.fechaSelectedHasta = this.grupoDevengamiento.dgVigenciaHasta;
      this.fechaHasta = this.parseDateToIso(
        this.grupoDevengamiento.dgVigenciaHasta
      );
      if (this.grupoDevengamiento.cobradores[0]?.id == -1)
        this.cobradoresSelect = this.cobradoresItems;
      else this.cobradoresSelect = this.grupoDevengamiento.cobradores;

      if (this.grupoDevengamiento.formasPago[0]?.id == -1)
        this.formasPagoSelect = this.formasPagoItems;
      else this.formasPagoSelect = this.grupoDevengamiento.formasPago;

      if (this.grupoDevengamiento.obrasSociales[0]?.id == -1)
        this.conveniosSelect = this.conveniosItems;
      else this.conveniosSelect = this.grupoDevengamiento.obrasSociales;
    },
    newGrupoDevengamiento() {
      this.formEditTitle = this.newTitle;
    },
    async setSelects() {
      const cobradores = await this.getCobradores();
      this.cobradoresItems = cobradores;
      const formasPago = await this.getFormasPago();
      this.formasPagoItems = formasPago;
      const convenios = await this.getConvenios();
      this.conveniosItems = convenios;
    },
    async saveEdit() {
      this.isFormValid = false;
      if (this.cobradoresSelect.length == this.cobradoresItems.length)
        this.cobradoresSelect = [{ id: -1, value: "Todos" }];

      if (this.conveniosSelect.length == this.conveniosItems.length)
        this.conveniosSelect = [{ id: -1, value: "Todos" }];

      if (this.formasPagoSelect.length == this.formasPagoItems.length)
        this.formasPagoSelect = [{ id: -1, value: "Todos" }];

      const data = {
        dgId:
          this.editGrupoDevengamientoId != null
            ? this.editGrupoDevengamientoId
            : null,
        dgNombre: this.dgNombre,
        dgVigenciaDesde: this.fechaSelectedDesde,
        dgVigenciaHasta: this.fechaSelectedHasta,
        obrasSociales: this.conveniosSelect,
        cobradores: this.cobradoresSelect,
        formasPago: this.formasPagoSelect
      };
      const res = await this.postGrupoDevengamiento(data);
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito" });
        this.closeModal();
        this.isFormValid = true;
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    cobradoresToggle() {
      this.$nextTick(() => {
        if (this.selectAllCobradores) this.cobradoresSelect = [];
        else this.cobradoresSelect = this.cobradoresItems;
      });
    },
    conveniosToggle() {
      this.$nextTick(() => {
        if (this.selectAllConvenios) this.conveniosSelect = [];
        else this.conveniosSelect = this.conveniosItems;
      });
    },
    formasPagoToggle() {
      this.$nextTick(() => {
        if (this.selectAllFormasPago) this.formasPagoSelect = [];
        else this.formasPagoSelect = this.formasPagoItems;
      });
    },
    closeModal() {
      this.$emit("closeAndReload");
    }
  }
};
</script>
